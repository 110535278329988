// Initialize Firebase

import * as firebase from "firebase/app"
import 'firebase/analytics'
// production code for console.log
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log("Development");
} else {
  console.log("Production");
  // console.log = () => {};
  
}
const config = {
  apiKey: "AIzaSyDS6GD59z0RVhZTA2PDwmaEmzYUbiSD8_Q",
  authDomain: "tssglobal-framez.firebaseapp.com",
  databaseURL: "https://tssglobal-framez-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "tssglobal-framez",
  storageBucket: "tssglobal-framez.appspot.com",
  messagingSenderId: "339429812820",
  appId: "1:339429812820:web:1eef64ed5c4b971b35dbc1",
  measurementId: "G-ZL3CMFQSX4"
};
firebase.initializeApp(config)
firebase.analytics();

